// import jsonp from "jsonp";
import React from "react";
import {
  Box,
  Button,
  Label,
  Input,
  Select,
  Textarea,
  Checkbox,
} from "theme-ui";
import Cookies from "universal-cookie";
import { CAMPAIGN_ID, KEYWORD, REFERRAL_COOKIE } from "../constants/cookies";
import { trackEvent, Events } from "./analytics";
import { CalendlyButton, CalendlyEventTypes } from "./form-calendly";

/** @jsx jsx */
import { jsx } from "theme-ui";
import { PHONE_REGEX } from "../pages/join";

export const FormTypes = {
  GENERAL_CONTACT: "general",
  VIRTUAL_MEMORIAL: "virtual_memorial",
  IN_PERSON_MEMORIAL: "in_person_memorial",
  COMBO_MEMORIAL: "combo_memorial",
  INTERACTIVE_LIVESTREAMS: "interactive_livestreams",
  SELF_GUIDED_MEMORIALS: "self_guided_memorials",
  CLOSING_ACCOUNTS: "closing_accounts",
  ARBOR_DIRECTOR: "arbor_director",
  ARBOR_FAMILY: "arbor_family",
  CANADIAN_FAMILY: "canadian_family",
  PAY_WHAT_YOU_CAN: "pay_what_you_can",
  HOSPITAL_BEREAVEMENT: "hospital_bereavement_guide",
  HERE_FOR_YOU: "here_for_you",
  HERE_FOR_YOU_APP: "here_for_you_app",
  HERE_FOR_YOU_PHONE: "here_for_you_phone",
  FUNERAL_HOMES: "funeral_homes",
  FUNERAL_SAAS: "funeral_saas",
  GPL: "funeral_gpl",
  NFDA_2023: "funeral_NFDA_2023",
};

const WHAT_NOW_FORMS = [
  FormTypes.HOSPITAL_BEREAVEMENT,
  FormTypes.HERE_FOR_YOU,
  FormTypes.HERE_FOR_YOU_APP,
  FormTypes.HERE_FOR_YOU_PHONE,
  FormTypes.FUNERAL_HOMES,
  FormTypes.GPL,
  FormTypes.NFDA_2023,
];

const LivestreamLocations = [
  "Atlanta, GA",
  "Buffalo, NY",
  "Chicagoland Area",
  "Dallas-Fort Worth, TX",
  "Detroit, MI",
  "Houston, TX",
  "Los Angeles Area",
  "Maryland / Washington, DC Area",
  "Miami, FL",
  "Minneapolis / Twin Cities, MN",
  "New Jersey",
  "New York Metropolitan Area",
  "Phoenix, AZ",
  "Rochester, NY",
  "San Diego Area",
  "Santa Rosa, CA",
  "Sarasota, FL",
  "Seattle Metropolitan Area",
  "St. Louis, MO",
];

const GOOGLE_FORM_URL =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfdS23DmWS9dCj4gPiHxFIwqJFxr4S1H-GcKZ1H_f8zEJ5zVQ/formResponse?";
// https://docs.google.com/forms/d/e/1FAIpQLSfdS23DmWS9dCj4gPiHxFIwqJFxr4S1H-GcKZ1H_f8zEJ5zVQ/viewform?usp=pp_url&
// entry.268113785=NAME
// &entry.1551976363=FIRST_NAME
// &entry.773255807=LAST_NAME
// &entry.748438390=EMAIL
// &entry.1241175523=SOMEONE --> phone
// &entry.1041583004=RELATIONSHIP --> referral
// &entry.507279240=DATE
// &entry.879287451=SERVICE_TIME
// &entry.354708231=H_NUMBER
// &entry.1758215376=LOCATION
// &entry.1810896443=FAVOURITE --> campaign
// &entry.2053917703=ANYTHING --> keyword
// &entry.297380672=COMMENTS
// &entry.1867173240=SUBSCRIBE
// &entry.1658902511=SOURCE

const locationToCampaignMap = {
  16517631274: "CHI-all",
  16275341247: "CHI-hospitals",
  16217196945: "NYC-hospitals",
};

export class ContactForm extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formStatus: null,
      email: "",
    };
  }

  sendData = (formData) => {
    let queryString = new URLSearchParams(formData).toString();
    let url = `${GOOGLE_FORM_URL}&${queryString}`;
    let opts = {
      method: "POST",
      mode: "no-cors",
      redirect: "follow",
      referrer: "no-referrer",
    };

    return fetch(url, opts)
      .then(() => {
        this.setState({ formStatus: "success" });
      })
      .catch((e) => {
        this.setState({ formStatus: "error" });
      });
  };

  handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    // Name
    if (data.get("entry.268113785")) {
      let fullName = data.get("entry.268113785");
      let firstName = "";
      let lastName = "";
      if (fullName.indexOf(" ") > -1) {
        firstName = fullName.substr(0, fullName.indexOf(" "));
        lastName = fullName.substring(firstName.length + 1); // +1 for the space
      } else {
        firstName = fullName;
      }
      data.append("entry.1551976363", firstName);
      data.append("entry.773255807", lastName);
    }

    // Ad Campaign and no location
    if (data.get("entry.1810896443") && !data.get("entry.1758215376")) {
      let campaignId = data.get("entry.1810896443");
      data.set("entry.1758215376", locationToCampaignMap[campaignId] || "");
    }

    if (data.get("entry.748438390")) {
      this.setState({ email: data.get("entry.748438390") });
    }

    this.sendData(data);
  }

  render() {
    const {
      formTitle,
      formSubtitle,
      formType,
      formOutro,
      buttonVariant,
      emailPlaceholder,
      buttonText,
      buttonTerms,
      knownState,
      calendlyType,
      calendlyMessageOverride,
      nestedForm,
      initialValues,
      signUpData,
    } = this.props;

    const isBrowser = typeof window !== "undefined" && window;
    if (!isBrowser) {
      return null;
    }

    // let desiredDate = true;
    let locationText = "Location (City)";
    if (knownState) {
      locationText = `City in ${knownState}`;
    }
    let name = true;
    let nameText = "Name";
    let location = true;
    let hNumber = false;
    let funeralHomeName = false;
    let favourites = true;
    // let anythingelse = true;
    let comments = false;
    let subscribe = true;
    let email = true;
    let phone = true;
    let serviceDate = false;
    let serviceTime = false;
    let hideImage = false;

    const cookies = new Cookies();
    let referralCode = cookies.get(REFERRAL_COOKIE);
    let campaignCode = cookies.get(CAMPAIGN_ID);
    let keywordCode = cookies.get(KEYWORD);

    const urlParams = new URLSearchParams(window.location.search);
    let sourceValue =
      formType + (urlParams.get("plan") ? "_" + urlParams.get("plan") : "");
    if (knownState) {
      sourceValue += "_" + knownState.toLowerCase();
    }
    let boxSx = {
      "& input": {
        backgroundColor: "white",
      },
      "& textarea": {
        backgroundColor: "white",
      },
    };

    if (
      formType === FormTypes.GENERAL_CONTACT ||
      formType === FormTypes.CLOSING_ACCOUNTS
    ) {
      // desiredDate = location = favourites = anythingelse = false;
      location = favourites = false;
      comments = true;
    } else if (formType === FormTypes.VIRTUAL_MEMORIAL) {
      location = false;
    } else if (formType === FormTypes.SELF_GUIDED_MEMORIALS) {
      location = false;
    } else if (formType === FormTypes.INTERACTIVE_LIVESTREAMS) {
      locationText = "Location (City / Metro Area)";
    } else if (
      formType === FormTypes.ARBOR_DIRECTOR ||
      formType === FormTypes.ARBOR_FAMILY ||
      formType === FormTypes.CANADIAN_FAMILY
    ) {
      favourites = comments = false;
      subscribe = false;
      phone = true;
      email = false;
      serviceDate = true;
      locationText = "Arbor Location";
      nameText = "Your Name";
      hideImage = true;

      if (formType === FormTypes.CANADIAN_FAMILY) {
        locationText = "Location (City)";
      }

      if (formType === FormTypes.ARBOR_DIRECTOR) {
        serviceTime = true;
        hNumber = true;
      }
    } else if (formType === FormTypes.PAY_WHAT_YOU_CAN) {
      location = false;
      phone = false;
      hideImage = true;
    } else if (formType === FormTypes.FUNERAL_SAAS) {
      favourites = false;
      location = false;
      subscribe = false;
      funeralHomeName = true;
      nameText = "Your Name";
      hideImage = true;
    } else if (WHAT_NOW_FORMS.indexOf(formType) > -1) {
      hideImage = true;
      location = false;
      favourites = false;
      subscribe = false;
      if (formType === FormTypes.HERE_FOR_YOU_PHONE) {
        phone = true;
        email = (initialValues && initialValues.email) || false;
      } else {
        email = true;
        phone = false;
      }
      name = false;
      boxSx = {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridGap: "16px",
        // margin: "0 auto",
        // maxWidth: "500px",
        "& input": {
          marginBottom: 0,
          backgroundColor: "white",
          fontSize: "16px",
          border: "2px solid #D9D9D9",
          "&:focus": {
            borderColor: "#E5C6A4",
          },
          "&::placeholder": {
            color: "rgba(102, 100, 94, 0.60)",
            fontWeight: 500,
          },
        },
        "& button": {
          fontSize: "16px",
          fontWeight: 500,
        },
      };
    }

    return (
      <>
        {this.state.formStatus !== "success" && (
          <Box
            as="form"
            onSubmit={(e) => {
              this.handleSubmit(e);
              trackEvent(Events.ACTION.FORM_SUBMIT, formType);
            }}
            id={"contact_" + formType}
            name={"contact_" + formType}
            sx={boxSx}
          >
            {formTitle && <h2>{formTitle}</h2>}
            {formSubtitle && <h4>{formSubtitle}</h4>}

            {/* "Source" */}
            <Input
              type="hidden"
              name="entry.1658902511"
              id="entry.1658902511"
              value={sourceValue}
            />

            {name && (
              <>
                <Label htmlFor="entry.268113785">{nameText}</Label>
                <Input
                  type="text"
                  name="entry.268113785"
                  id="entry.268113785"
                  required
                  mb={3}
                />
              </>
            )}

            {funeralHomeName && (
              <>
                <Label htmlFor="entry.354708231">Funeral Home Name</Label>
                <Input
                  type="text"
                  required
                  name="entry.354708231"
                  id="entry.354708231"
                  mb={3}
                />
              </>
            )}

            {/* MERGE0 */}
            {email && (
              <>
                {WHAT_NOW_FORMS.indexOf(formType) === -1 && (
                  <Label htmlFor="entry.748438390">Email</Label>
                )}
                <Input
                  type={
                    formType === FormTypes.HERE_FOR_YOU_PHONE &&
                    initialValues?.email
                      ? "hidden"
                      : "email"
                  }
                  sx={{ color: "text" }}
                  name="entry.748438390"
                  id="entry.748438390"
                  required
                  placeholder={
                    WHAT_NOW_FORMS.indexOf(formType) > -1
                      ? emailPlaceholder || "Enter your email"
                      : ""
                  }
                  value={initialValues && initialValues.email}
                  mb={3}
                />
              </>
            )}

            {phone && (
              <>
                {WHAT_NOW_FORMS.indexOf(formType) === -1 && (
                  <Label htmlFor="entry.1241175523">Phone Number</Label>
                )}
                <Input
                  type={
                    formType === FormTypes.HERE_FOR_YOU_PHONE ? "tel" : "text"
                  }
                  name="entry.1241175523"
                  id="entry.1241175523"
                  pattern={
                    formType === FormTypes.HERE_FOR_YOU_PHONE
                      ? PHONE_REGEX
                      : null
                  }
                  title={
                    formType === FormTypes.HERE_FOR_YOU_PHONE
                      ? "Please enter a valid phone number"
                      : null
                  }
                  placeholder={
                    WHAT_NOW_FORMS.indexOf(formType) > -1
                      ? emailPlaceholder
                      : ""
                  }
                  required={formType === FormTypes.FUNERAL_SAAS}
                  mb={3}
                />
              </>
            )}

            {/* <Label htmlFor="MERGE8">Your Relationship</Label>
            <Input
              type="text"
              name="entry.1041583004"
              id="entry.1041583004"
              mb={3}
            /> */}
            <Input
              type="hidden"
              name="entry.1041583004"
              id="entry.1041583004"
              value={referralCode}
            />

            <Input
              type="hidden"
              name="entry.1810896443"
              id="entry.1810896443"
              value={campaignCode}
            />

            <Input
              type="hidden"
              name="entry.2053917703"
              id="entry.2053917703"
              value={keywordCode}
            />

            {location && formType !== FormTypes.INTERACTIVE_LIVESTREAMS && (
              <>
                <Label htmlFor="entry.1758215376">{locationText}</Label>
                <Input
                  type="text"
                  name="entry.1758215376"
                  id="entry.1758215376"
                  mb={3}
                />
              </>
            )}

            {location && formType === FormTypes.INTERACTIVE_LIVESTREAMS && (
              <>
                <Label htmlFor="entry.1758215376">{locationText}</Label>
                <Select
                  name="entry.1758215376"
                  id="entry.1758215376"
                  required
                  mb={3}
                >
                  <option value=""></option>
                  {LivestreamLocations.map((option) => {
                    return <option value={option}>{option}</option>;
                  })}
                </Select>
              </>
            )}

            {hNumber && (
              <>
                <Label htmlFor="entry.354708231">H Number</Label>
                <Input
                  type="text"
                  name="entry.354708231"
                  id="entry.354708231"
                  mb={3}
                />
              </>
            )}

            {serviceDate && (
              <>
                <Label htmlFor="entry.507279240">Service Date</Label>
                <Input
                  name="entry.507279240"
                  id="entry.507279240"
                  type="date"
                  mb={3}
                />
              </>
            )}

            {serviceTime && (
              <>
                <Label htmlFor="entry.879287451">Service Time</Label>
                <Input
                  name="entry.879287451"
                  id="entry.879287451"
                  type="text"
                  mb={3}
                />
              </>
            )}

            {/* Though we are tracking this with the favourites variable,
                we're using the input id for "Comments" so all comments show up together. */}
            {favourites && (
              <>
                <Label htmlFor="entry.297380672">
                  Tell us a bit about your someone
                </Label>
                <Textarea
                  name="entry.297380672"
                  id="entry.297380672"
                  rows="2"
                  mb={3}
                  placeholder="Don’t worry if you don’t know where to start. We can help when we chat."
                />
              </>
            )}

            {comments && (
              <>
                <Label htmlFor="entry.297380672">Your Message</Label>
                <Textarea
                  name="entry.297380672"
                  id="entry.297380672"
                  rows="2"
                  mb={3}
                  placeholder="Share anything you'd like us to know"
                />
              </>
            )}

            {signUpData && (
              <>
                <Input
                  type="hidden"
                  name="entry.297380672"
                  id="entry.297380672"
                  value={signUpData}
                />
              </>
            )}

            {subscribe && (
              <Box>
                <Label mb={3}>
                  <Checkbox name="entry.1867173240" id="entry.1867173240" />
                  Also subscribe to our Newsletter
                </Label>
              </Box>
            )}

            <Button variant={buttonVariant || "primary"}>
              {buttonText || "Submit"}
            </Button>
            {buttonTerms && buttonTerms}
          </Box>
        )}

        {this.state.formStatus === "success" && (
          <Box mb={2}>
            <p>{formOutro || "Thanks for submitting!"}</p>
            {nestedForm && (
              <ContactForm
                {...nestedForm}
                initialValues={{ email: this.state.email }}
              />
            )}
            {calendlyType && (
              <div sx={{ marginBottom: "24px" }}>
                <p>
                  {calendlyMessageOverride ||
                    "If you'd like to schedule a chat, book time with us below"}
                </p>
                <CalendlyButton
                  buttonOverride="Schedule call"
                  eventType={calendlyType}
                  buttonVariant={buttonVariant}
                />
              </div>
            )}
            {!hideImage && (
              <Box sx={{ maxWidth: "350px", margin: "0 auto" }}>
                <img
                  alt="illustration of colorful balloons attached to love notes"
                  src="/images/balloons-color.jpg"
                  width="100%"
                />
              </Box>
            )}
          </Box>
        )}
      </>
    );
  }
}

export const BereavementContact = ({
  hideCalendly,
  formOutro,
  buttonText,
  emailPlaceholder,
  formType,
}) => {
  formType = formType || FormTypes.HERE_FOR_YOU;
  formOutro = formOutro || "Our team will reach out to you shortly.";

  let nestedForm = null;
  if (formType === FormTypes.HERE_FOR_YOU) {
    nestedForm = {
      buttonVariant: "blueButton",
      formType: FormTypes.HERE_FOR_YOU_PHONE,
      formTitle: "",
      formOutro: " ",
      emailPlaceholder: "Enter your phone number",
      hideImage: true,
      buttonText: "Submit",
      calendlyType: null,
    };
    formOutro +=
      " If you'd prefer a call or text, you can share your phone number.";
  }

  return (
    <ContactForm
      buttonVariant="blueButton"
      formType={formType}
      formTitle=""
      formOutro={formOutro}
      emailPlaceholder={emailPlaceholder}
      hideImage={true}
      buttonText={buttonText || "Contact Us"}
      nestedForm={nestedForm}
      calendlyType={!hideCalendly && CalendlyEventTypes.FUNERAL_HOMES}
      calendlyMessageOverride={
        "You can also schedule a time that works best for you."
      }
    />
  );
};
