import React from "react";
import { Link } from "gatsby";
import { trackEvent, Events } from "../components/analytics";
import Layout from "../components/layout-main";
import { FirebaseContext } from "../services";
import "firebase/firestore";

/** @jsx jsx */
import { jsx, Box, Input, Label, Button, Textarea } from "theme-ui";
import Routes from "../constants/routes";

import Cookies from "universal-cookie";
import { JOIN_ID, AFTERWORD_COOKIE_PARAMS } from "../constants/cookies";

export const GuestEventTypes = {
  JOIN: "JOIN",
  RSVP: "RSVP",
  VIEW: "VIEW",
  POST: "POST",
};

// Notice the escaped slashes, so \\w renders as \w, the actual regex expression.
export const PHONE_REGEX = "([()+* .#-]*(?:\\d[()+* .#-]*){7,40})";
let emailRegex = "(\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+)";
const phoneAndEmailRegex = `${PHONE_REGEX}|${emailRegex}`;

const invalidInputStyle = {
  "&:not(:placeholder-shown):not(:focus):invalid": {
    border: "2px solid",
    borderColor: "orange",
  },
};

const isBrowser = typeof window !== "undefined" && window;

const JoinPage = ({ location }) => {
  const { db, isInitialized } = React.useContext(FirebaseContext);
  const pageSlug = String(location.pathname)
    .split(Routes.JOIN_PREFIX)[1]
    .replace(/\//g, "");
  const cookies = new Cookies();

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (pageSlug) {
      let query = db
        .collection("humans")
        .where("pageSlug", "==", pageSlug)
        .where("isPublished", "==", true)
        .limit(1);
      query
        .get()
        .then(function (result) {
          let data;
          if (result.forEach && typeof result.forEach === "function") {
            result.forEach(function (doc) {
              data = doc.data();
              setHumanId(doc.id);
            });
            setFoundHuman(data);
          } else if (result.exists) {
            data = result.data();
            setHumanId(result.id);
            if (data && data.password) {
              let password = window.prompt("Please enter the password");
              if (password !== data.password) {
                data = null;
              }
            }
            setFoundHuman(data);
          } else {
            setFoundHuman(null);
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        })
        .finally(() => {
          setJoinCookie(cookies.get(JOIN_ID));
          setIsLoaded(true);
        });
    } else {
      setIsLoaded(true);
    }
  }, [isInitialized]);

  const [foundHuman, setFoundHuman] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showDirectZoomUrl, setShowDirectZooomUrl] = React.useState(false);
  const [humanId, setHumanId] = React.useState("");
  const [joinCookie, setJoinCookie] = React.useState({});

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {foundHuman && isLoaded && (
        <Layout
          location={location}
          pageTitle={"Join the Service for " + foundHuman.firstName}
          hideLinks={true}
          hideSubscribe={true}
          noIndex={true}
        >
          <section className="aw-accent">
            <div
              className="aw-content"
              sx={{ textAlign: "center", maxWidth: "620px" }}
            >
              <h1>Join the Memorial for {foundHuman.firstName}</h1>
              <p>
                If you haven't used Zoom before, please&nbsp;
                <a
                  href={Routes.JOINING_ZOOM}
                  aria-describedby="a11y-external-message"
                >
                  use our tutorial for how to join
                </a>
                .
              </p>
              <Box
                as="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target;
                  const data = new FormData(form);
                  trackEvent(
                    Events.ACTION.FORM_SUBMIT,
                    "guestEvents_" + GuestEventTypes.JOIN
                  );
                  let eventDoc = db
                    .collection("humans")
                    .doc(humanId)
                    .collection("guestEvents")
                    .doc();
                  eventDoc
                    .set({
                      name: data.get("name"),
                      emailPhone: data.get("emailPhone"),
                      message: data.get("message"),
                      address: data.get("address"),
                      type: GuestEventTypes.JOIN,
                      date: new Date(),
                    })
                    .catch((error) => {
                      console.log(error);
                      setShowDirectZooomUrl(true);
                    })
                    .finally(() => {
                      let formattedJoinCookie = {
                        ...joinCookie,
                        name: data.get("name"),
                        emailPhone: data.get("emailPhone"),
                        address: data.get("address"),
                      };
                      cookies.set(
                        JOIN_ID,
                        JSON.stringify(formattedJoinCookie),
                        AFTERWORD_COOKIE_PARAMS
                      );
                      window.open(foundHuman.zoomSettings.url, "_self");
                      // Some browsers may block pop-ups the first time, so
                      // we reserve the "_self" frame for the Zoom URL itself
                      // and open the Stories page in a new tab.
                      window.open(
                        `${Routes.STORIES_PREFIX}/${foundHuman.pageSlug}`,
                        "_blank"
                      );
                      setShowDirectZooomUrl(true);
                    });
                }}
                id={"join-" + pageSlug}
                name={"join-" + pageSlug}
              >
                <Label htmlFor="name">Your Name(s) *</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder=" "
                  defaultValue={(joinCookie && joinCookie.name) || ""}
                  autoFocus
                  mb={3}
                  sx={invalidInputStyle}
                />

                <Label htmlFor="emailPhone">
                  Email Address or Phone Number *
                </Label>
                <Input
                  type="text"
                  name="emailPhone"
                  id="emailPhone"
                  required
                  placeholder=" "
                  sx={invalidInputStyle}
                  defaultValue={(joinCookie && joinCookie.emailPhone) || ""}
                  pattern={phoneAndEmailRegex}
                  title="Please enter a valid email address or phone number"
                  mb={3}
                />

                {/* <Label htmlFor="address">
                  The family requests your mailing address (Street, City, State/Province, Postal) (optional)
                </Label>
                <Textarea
                  type="text"
                  name="address"
                  id="address"
                  defaultValue={(joinCookie && joinCookie.address) || ""}
                  mb={3}
                /> */}

                <Label htmlFor="message">
                  Private Message to the Family (optional)
                </Label>
                <Textarea
                  type="text"
                  name="message"
                  id="message"
                  defaultValue={(joinCookie && joinCookie[pageSlug]) || ""}
                  mb={3}
                />

                <Button
                  variant={"primary"}
                  sx={{ width: ["100%"], height: "42px", fontWeight: "bold" }}
                >
                  {foundHuman.zoomSettings.url.indexOf("zoom.us/j/") > -1
                    ? "Join Memorial with Zoom"
                    : "Join Memorial"}
                </Button>
                {foundHuman && foundHuman.zoomSettings.id && (
                  <>
                    {showDirectZoomUrl && (
                      <p
                        sx={{
                          marginTop: "12px",
                        }}
                      >
                        Still here? You can join the Memorial with this link:{" "}
                        <Link to={foundHuman.zoomSettings.url} target="_blank">
                          {foundHuman.zoomSettings.url}
                        </Link>
                      </p>
                    )}
                    <p
                      sx={{
                        marginTop: "12px",
                      }}
                    >
                      Zoom Meeting ID: {foundHuman.zoomSettings.id}, Passcode:{" "}
                      {foundHuman.zoomSettings.passcode}, & Dial-in:{" "}
                      {foundHuman.zoomSettings.phone}
                    </p>
                  </>
                )}
              </Box>
              <img
                src={foundHuman?.imageUrl || foundHuman.imgUrl}
                alt={foundHuman.firstName}
                title={foundHuman.firstName}
                sx={{
                  objectFit: "contain",
                  maxWidth: ["335px", "600px"],
                  marginTop: "20px",
                }}
              />
              <a
                sx={{ display: "block" }}
                href={Routes.STORIES_PREFIX + "/" + pageSlug}
              >
                Back to Memorial Page
              </a>
            </div>
          </section>
        </Layout>
      )}
      {!foundHuman && isLoaded && (
        <Layout location={location} pageTitle="Not Found">
          <section>
            <div className="aw-content">
              <h1>We couldn't find this person</h1>
              <p>
                Sorry. You visited a page that doesn&#39;t exist or isn't
                accessible to you.
              </p>
            </div>
          </section>
        </Layout>
      )}
      {!isLoaded && <div></div>}
    </>
  );
};

export default JoinPage;
