import React from "react";
import { Helmet } from "react-helmet";
import { trackButtonCta } from "../components/analytics";

import { Button } from "theme-ui";
import Cookies from "universal-cookie";
import { CAMPAIGN_ID, KEYWORD } from "../constants/cookies";
/** @jsx jsx */
import { jsx } from "theme-ui";

export const CalendlyEventTypes = {
  CONSULTATION:
    "https://calendly.com/afterword/consultation?background_color=FFF&text_color=2C2932&primary_color=657075",
  PLANNING:
    "https://calendly.com/afterword/planning?background_color=FFF&text_color=2C2932&primary_color=657075",
  // planning@
  FUNERAL_HOMES:
    "https://calendly.com/afterword-planning/funeral-homes?background_color=FFF&text_color=2C2932&primary_color=657075",
  HOSPITAL_BEREAVEMENT:
    "https://calendly.com/afterword-bereavement/training-session?background_color=FFF&text_color=2C2932&primary_color=657075",
  HERE_FOR_YOU:
    "https://calendly.com/afterword-support/initial-call?background_color=FFF&text_color=000000&primary_color=487A98",
};

class CalendlySection extends React.Component {
  render = () => {
    let {
      headerOverride,
      buttonOverride,
      calendlyType,
      buttonVariant,
      unaccented,
      sectionSx,
      contentSx,
    } = this.props;
    return (
      <>
        <section
          className={unaccented ? "" : "aw-accent"}
          sx={{ ...sectionSx }}
        >
          <div
            className="aw-content"
            sx={{ textAlign: "center", padding: "45px 0", ...contentSx }}
          >
            <h2>
              {headerOverride ||
                "Not sure where to start? Schedule a free 30 minute consultation with us"}
            </h2>
            <CalendlyButton
              headerOverride={headerOverride}
              buttonOverride={buttonOverride}
              buttonVariant={buttonVariant}
              eventType={calendlyType}
            />
          </div>
        </section>
      </>
    );
  };
}

export class CalendlyButton extends React.Component {
  calendlyPopup = (url) => {
    let eventName = this.props.pageName || "virtual-funerals";
    trackButtonCta(`calendlypopup-${eventName}`);
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: url,
      });
    }
  };

  render = () => {
    let { buttonOverride, eventType, buttonVariant } = this.props;

    if (!eventType) {
      eventType = CalendlyEventTypes.CONSULTATION;
    }
    const cookies = new Cookies();
    let campaignCode = cookies.get(CAMPAIGN_ID);
    let keywordCode = cookies.get(KEYWORD);
    if (campaignCode || keywordCode) {
      eventType += `&utm_term=${keywordCode}&utm_campaign=${campaignCode}`;
    }

    return (
      <>
        <Helmet>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
          ></script>
          <style>{`
        body {
            overflow: visible !important;
        }
        `}</style>
        </Helmet>
        <a
          href="#schedule"
          onClick={() => {
            this.calendlyPopup(eventType);
          }}
        >
          <Button variant={buttonVariant || "primary"}>
            {buttonOverride || "Schedule Consultation"}
          </Button>
        </a>
      </>
    );
  };
}

export default CalendlySection;
